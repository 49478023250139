import SongParser from "./SongParser"
import fetchSong from "./fetchSong"

const root = document.getElementById('root') as HTMLDivElement
const parser = new SongParser()

const initialize =  async () => {
    const songSlug = new URL(window.location.href).searchParams.get('song') || null
    if (songSlug === null) {
        return
    }
    const content = await fetchSong(songSlug)
    const song = parser.parse(content)
    const chordImgs = song.chords.map(chord => {
        return `<span data-chord="${chord}"><img src="/songs/chrods/${chord.toLowerCase()}.png"/></span>`
    })
    root.innerHTML = `
    <header>
        <h1>${song.title}</h1>
        <h2>${song.author}</h2>
        <p>${song.rhythm}</p>
        <section>${chordImgs.join('')}</section>
    </header>
    <main>${song.html}</main>
    `
}
initialize()