
class Song {

    author: string = '[NO AUTHOR]'
    title: string = '[NO TITLE]'
    rhythm: string = ''

    html: string = ''
    chords: string[] = []

    addChord(chord: string) {
        if (this.chords.includes(chord)) {
            return false
        }
        this.chords.push(chord)
        return true
    }

}

class SongParser {

    private keyParsers: Map<string, (value: string) => any> = new Map()

    constructor() {
        this.keyParsers.set('rhythm', this.parseRhythm)
    }

    parse(content: string): Song {
        const song = new Song()
        const lines = content.split('\n').map(line => line.trim())
        
        while(lines.length > 0) {
            const line = lines.shift()
            if (typeof line === 'undefined') break
            const characters = this.getUniqueCharacters(line)
            if (characters.join('') === '-') {
                break
            }
            const [ key, value ] = line.split(':')
            if (typeof value !== 'string') {
                continue
            }
            if (key === '') {
                continue
            }
            const keys = Object.keys(song)
            if (!keys.includes(key.trim())) {
                console.log(`key=${key} is not handled`)
                continue
            }
            let cleanValue = value.trim()
            if (this.keyParsers.has(key)) {
                let parser = this.keyParsers.get(key) as (value: string) => any
                cleanValue = parser(cleanValue) as string
            }
            song[key] = cleanValue
        }

        
        const groups: string[][] = []
        let current: string[] = []
        for (let line of lines) {
            if (line === '') {
                groups.push(current)
                current = []                
            } else {
                const { html, chrods } = this.lineToHTML(line)
                current.push(html)
                chrods.forEach(chord => {
                    const filtered = chord.split('').filter(char => char !== '#').join('')
                    song.addChord(filtered)
                })
            }
        }
        if (current.length > 0) {
            groups.push(current)
        }
        song.html = groups.map(lines => {
            const htmlLines = lines.map(line => `<p>${line}</p>`)
            return `<section>${htmlLines.join('')}</section>`
        }).join('')

        return song
    }

    private getUniqueCharacters(text: string) {
        const list: string[] = []
        text.split('').forEach(character => {
            if (!list.includes(character)) {
                list.push(character)
            }
        })
        return list
    }

    private lineToHTML(line: string) {
        const parsedLine: string[] = []
        const chords = new Set<string>()
        const characters = line.split('')
        while(characters.length > 0) {
            const character = characters.shift() as string
            if (character !== '[') {
                parsedLine.push(character)
                continue
            }
            const buffer: string[] = []
            let index = 0
            do {
                const character = characters.shift() as string
                if (character === ']') {
                    break
                }
                buffer.push(character)

            } while(index < 10)
            if (buffer.length === 0) {
                continue
            }
            const chord = buffer.join('')

            chords.add(chord)
            parsedLine.push(`<span>${chord}</span>`)
        }
        return {
            chrods: Array.from(chords),
            html: parsedLine.join('')
        }
    }

    private parseRhythm(value: string) {
        return value.split('').map(direction => {
            if (!Number.isNaN(parseInt(direction, 10))) {
                return direction
            } else if (direction === 'U') {
                return '↑'
            } else if (direction === 'D') {
                return '↓'
            } else if (direction === '|') {
                return ' | '
            }
        }).join('')
    }

}

export default SongParser